import React from "react"
import { graphql } from "gatsby"
import * as transformer from "../gatsby/transformers/productTaxonomyTransformer"
import ProductTaxonomyPage from "../social-supermarket/pages/product-taxonomy/ProductTaxonomyPage"
import { filterByVisibility } from "../social-supermarket/util/productUtil"

const CollectionTemplate = ({ data: { wpCollection }, pageContext: { products, queryId } }) => {
  const taxonomy = transformer.fromGatsbyNoProducts(wpCollection, "collection")
  taxonomy.products = filterByVisibility(products, "Marketplace")
  return (
    <ProductTaxonomyPage
      taxonomy={taxonomy}
      collectionName={`Collection:  ${taxonomy.name}`}
      queryId={queryId}
    />
  )
}

export default CollectionTemplate

export const query = graphql`
  query ($id: String) {
    wpCollection(id: { eq: $id }) {
      id
      slug
      name
      description
    }
  }
`
